<template>
  <div class="interviews">
    <v-card flat>
      <v-row no-gutters justify="end" class="px-3"
        ><v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="primary"
              dark
              @click.prevent="handleCreate()"
              >Agendar
            </v-btn>
          </template>
          <span>Agendar Entrevista</span>
        </v-tooltip>
      </v-row>
      <v-row class="mx-0">
        <label class="col-12 py-0">Filtrar por:</label>
        <v-col cols="12" lg="3" md="4" sm="6">
          <v-text-field
            dense
            solo
            hide-details="auto"
            label="Búsqueda"
            v-model="filters.search"
          />
        </v-col>
        <v-col cols="12" sm class="d-flex align-end justify-end">
          <v-btn
            small
            color="primary"
            max-width="35"
            min-width="auto"
            dark
            class="px-1 py-1"
            v-if="filterActivated"
            @click.prevent="
              filterActivated = false
              cleanFilters()
              getInterviews()
            "
          >
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                max-width="35"
                min-width="auto"
                dark
                small
                class="px-1 py-1 ml-1"
                @click.prevent="
                  filterActivated = true
                  getInterviews()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loadingInterviews"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="extraButtoms"
        :showCreate="false"
        :showDelete="true"
        :showEdit="false"
        :showPagination="pages > 1 ? true : false"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
        @handleShowData="showJobApplication($event)"
      />
    </v-card>
    <modal name="interviewModal" :height="'80%'" :width="'80%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4 mx-3">
          {{ flow === 'create' ? 'Crear Entrevista' : 'Editar Entrevista' }}
        </h2>

        <span v-if="item.created_at" class="mx-3"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >

        <v-form ref="interviewForm" class="my-5" v-model="interview.validation">
          <v-row class="mx-0">
            <v-col cols="12" sm="6" lg="3">
              <span class="caption text--secondary">Moderador</span>
              <multiselect
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                :customLabel="fullName"
                placeholder="Moderador"
                :loading="loadingModerators"
                v-model="interview.user"
                :options="moderators"
                :multiple="false"
                preselect-first
                :allow-empty="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
                @search-change="getModerators"
                :disabled="!interview.editable"
              />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
              <span class="caption text--secondary">Tipo de entrevista</span>
              <multiselect
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                preselect-first
                placeholder="Tipo de entrevista"
                :loading="loadingModerators"
                v-model="interview.type"
                :options="interviewOptions"
                :multiple="false"
                :allow-empty="false"
                :close-on-select="true"
                :disabled="!interview.editable"
              />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
              <span class="caption text--secondary">Estatus de la entrevista</span>
              <multiselect
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                placeholder="Selecciona un estatus"
                v-model="interview.status"
                :options="statusInterviews"
                :multiple="false"
                preselect-first
                :allow-empty="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
                :disabled="interview.status && interview.status.id !== 0"
              />
            </v-col>
            <v-col cols="12" v-if="interview.type && interview.type.id === 0">
              <v-text-field
                :rules="[rules.required, rules.url]"
                label="Link de la reunión"
                v-model="interview.url"
                :readonly="!interview.editable"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                auto-grow
                rows="1"
                counter="200"
                :rules="[rules.required, rules.max]"
                label="Observación"
                v-model="interview.observation"
                :readonly="!interview.editable"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                auto-grow
                rows="1"
                counter="200"
                :rules="[rules.required, rules.max]"
                label="Detalles"
                v-model="interview.details"
                :readonly="!interview.editable"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                auto-grow
                rows="1"
                counter="200"
                :rules="[rules.required, rules.max]"
                label="Conclusión"
                v-model="interview.conclusion"
                v-if="flow === 'edit'"
                :disabled="!interview.editable"
              />
            </v-col>
            <span class="text-center text--secondary col-12">
              Fecha y hora de la reunión</span
            >
            <v-col cols="12" sm="6" class="d-flex justify-center">
              <v-date-picker
                scrollable
                v-model="interview.date"
                :readonly="!interview.editable"
              />
            </v-col>
            <v-col cols="12" sm="6" class="d-flex justify-center">
              <v-time-picker
                ampm-in-title
                v-model="interview.time"
                :readonly="!interview.editable"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-col cols="12" class="mb-5">
        <v-row no-gutters>
          <v-btn
            class="mx-auto"
            small
            v-if="flow === 'create'"
            :disabled="!interview.validation"
            color="primary"
            @click="createItem()"
          >
            Agendar
          </v-btn>
          <v-btn
            class="mx-auto"
            small
            v-if="flow === 'edit' && interview.editable"
            :disabled="!interview.validation"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            v-if="interview.editable"
            class="mx-auto"
            small
            color="primary"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            v-if="flow === 'edit'"
            class="mx-auto"
            small
            color="primary"
            @click="interview.editable = !interview.editable"
          >
            Editar
          </v-btn>
          <v-btn
            class="mx-auto"
            small
            color="primary"
            @click="$modal.hide('interviewModal')"
          >
            {{ !interview.editable ? 'Cerrar' : 'Cancelar' }}
          </v-btn>
        </v-row>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'

  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    name: 'Interviews',
    components: {
      SimpleList,
    },
    props: {
      applicationId: {
        type: Number,
        required: true,
      },

      companyId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        createdAt: '',
        toDay: new Date(),
        dateRange: '',

        headers: [
          {
            text: '#',
            value: 'id',
          },
          { text: 'Estatus', value: 'statusText' },
          { text: 'Tipo', value: 'typeText' },
          { text: 'Fecha', value: 'timeText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ],
        items: [],
        page: 1,
        pages: 1,

        item: {},
        flow: '',
        totalItems: 0,
        itemsPerPage: 10,
        loadingInterviews: false,
        loadingModerators: false,

        moderators: [],
        editInterview: false,
        interviews: [],
        interview: {
          editable: false,
          validation: false,
          status: null,
          user: null,
          type: null,
          observation: '',
          conclusion: '',
          url: '',
          details: '',
          date: null,
          time: null,
        },
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        statusInterviews: [
          {
            id: 0,
            name: 'Pendiente',
            color: 'amber darken-2',
          },
          {
            id: 1,
            name: 'Completada',
            color: 'green darken-2',
          },
          {
            id: 2,
            name: 'Cancelada',
            color: 'red darken-2',
          },
        ],
        filters: {
          search: '',
        },
        interviewOptions: [
          { id: 0, name: 'Online' },
          { id: 1, name: 'Presencial' },
        ],
        loadingText: 'Cargando registros',
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
        },
        extraButtoms: [
          {
            text: 'Ver solicitud',
            icon: 'mdi-eye',
            event: { active: true, name: 'handleShowData' },
          },
        ],
        navigationElements: [
          {
            text: 'Talento Humano',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/AdminPanel',
            href: '/administrative/human-talent/admin-panel',
          },
          {
            text: 'Bolsa de empleo',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/JobBank',
            href: '/administrative/human-talent/manuals',
          },
          {
            text: 'Solicitudes',
            disabled: true,
          },
        ],
      }
    },
    created() {
      this.getInterviews()
      this.getModerators()
    },
    computed: {},

    methods: {
      toLocaleDateString: toLocaleDateString,
      fullName({ name, lastname, email }) {
        if (name && name !== 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },
      interviewDateTimeFormat(datetime) {
        let locales = ''
        const date = new Date(datetime)
        const miliseconds = date.getTime()
        const minutesOffset = date.getTimezoneOffset()
        navigator.languages && navigator.languages.length
          ? (locales = navigator.languages[0])
          : (locales = navigator.language)

        return new Date(miliseconds - minutesOffset * 60 * 1000).toLocaleTimeString(
          locales,
          {
            weekday: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            timeZone: localStorage.getItem('timeZone'),
          }
        )
      },

      cleanFilters() {
        this.filters = {
          search: '',
        }
      },
      async getModerators(search) {
        this.loadingModerators = true
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }

        this.$axios
          .get(
            'users?order=name&by=asc&limit=100&company_id=' + this.companyId + searchStr
          )
          .then((response) => {
            this.moderators = response.data
            this.loadingModerators = false
          })
      },

      async getInterviews() {
        this.loadingInterviews = true
        let queryParams = ''
        let orderString = ''
        let searchString = ''
        const withString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
        }

        queryParams = searchString + withString

        this.$axios
          .get(
            'interviews?job_application_id=' +
              this.applicationId +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.map((x) => {
              x.type === 0 ? (x.typeText = 'Online') : (x.typeText = 'Presencial')

              if (x.status !== true) {
                x.showDelete = true
              }
              switch (x.status) {
                case 0:
                  x.statusText = 'Pendiente'
                  break
                case 1:
                  x.statusText = 'Completada'
                  break
                case 2:
                  x.statusText = 'Cancelada'
                  break
              }
              x.timeText = this.interviewDateTimeFormat(x.datetime)
              return x
            })
            this.loadingInterviews = false
          })
      },
      handleCreate() {
        this.flow = 'create'
        this.interview = {
          editable: true,
          validation: false,
          status: null,
          user: null,
          type: null,
          observation: '',
          conclusion: '',
          url: '',
          details: '',
          date: null,
          time: null,
        }
        this.$modal.show('interviewModal')
      },
      handleEdit(item) {
        this.item = item
        this.flow = 'edit'
        this.interview.editable = false
        this.interview.validation = false
        this.interview.user = this.moderators.find(
          (moderator) => moderator.id === item.user_id
        )
        this.interview.status = this.statusInterviews.find(
          (status) => status.id === item.status
        )
        this.interview.type = this.interviewOptions.find((type) => type.id === item.type)
        this.interview.observation = item.observation
        this.interview.conclusion = item.conclusion
        this.interview.url = item.url
        this.interview.details = item.details
        this.interview.date = item.datetime.split(' ')[0]
        this.interview.time = item.datetime.split(' ')[1]
        this.createdAt = new Date(item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )
      },
      async createItem() {
        const data = {}
        const date = new Date(`${this.interview.date}T${this.interview.time}:00`)
        data.status = this.interview.status.id
        data.user_id = this.interview.user.id
        data.job_application_id = this.applicationId
        data.observation = this.interview.observation
        data.type = this.interview.type.id
        data.datetime = date.toISOString().split('.')[0]

        if (this.interview.url !== '' && this.interview.type.id === 0) {
          data.url = this.interview.url
        }
        if (this.interview.details !== '') {
          data.details = this.interview.details
        }

        this.$axios.post('interviews', data).then(() => {
          this.getInterviews()
          this.$modal.hide('interviewModal')
        })
      },
      async saveItem() {
        const data = {}
        const date = new Date(`${this.interview.date}T${this.interview.time}`)
        data.status = this.interview.status.id
        data.user_id = this.interview.user.id
        data.job_application_id = this.item.id
        data.observation = this.interview.observation
        data.conclusion = this.interview.conclusion
        data.type = this.interview.type.id
        data.datetime = date.toISOString().split('.')[0]
        if (this.interview.url !== '' && this.interview.type.id === 0) {
          data.url = this.interview.url
        }
        if (this.interview.details !== '') {
          data.details = this.interview.details
        }
        this.$axios.patch('interviews/' + this.item.id, data).then(() => {
          this.getInterviews()
          this.$modal.hide('interviewModal')
        })
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getInterviews()
      },
      async showJobApplication(item) {
        this.handleEdit(item)
        this.getInterviews()
        this.$modal.show('interviewModal')
      },
      validate() {
        if (!this.interview.validation && !this.interview.date && !this.interview.time) {
          return true
        } else {
          return false
        }
      },
      reset() {
        this.interview.date = ''
        this.interview.time = ''
        this.$refs.interviewForm.reset()
        this.$refs.interviewForm.resetValidation()
      },
      isURL(str) {
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },
      handleSkillValue(type, skill) {
        switch (type) {
          case 'integer':
            return parseInt(skill.value)
          case 'number':
            return skill.value
          case 'boolean':
            return 'Requerido'

          default:
            return null
        }
      },

      async deleteItem(item) {
        this.$axios.delete('interviews/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.loadingInterviews = true
          this.getInterviews()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getInterviews()
      },
      timelineDateString(date, locales) {
        try {
          return new Date(date).toLocaleDateString(locales, {
            day: 'numeric',
            month: 'long',
            timeZone: 'UTC',
          })
        } catch (error) {
          console.warn(error)
          return 'Invalid data'
        }
      },
    },
  }
</script>
<style lang="scss">
  .interviews {
    // .v-picker__body {
    //   margin: 0;
    // }
    .v-picker > {
      font-size: 0.8rem;
    }
    .v-picker__body {
      width: 100% !important;
      max-width: 250px;
      min-width: 200px;
    }
    .v-time-picker-title {
      justify-content: center;
    }
    .v-date-picker-title__date {
      font-size: 1.5rem;
    }
    .v-time-picker-title__time .v-picker__title__btn,
    .v-time-picker-title__time span {
      font-size: 2.5rem;
      margin: 0 auto;
    }
    .v-date-picker-header,
    .v-date-picker-table {
      padding: 4px 8px;
    }
    .v-picker__title {
      padding: 8px;
    }
  }
</style>
