<template>
  <div class="vuse-content-wrapper">
    <smart-breadcrumbs :items="navigationElements" />
    <!-- Content -->
    <v-card>
      <v-row align="center" class="mx-0 pa-3 text-center flex-column">
        <h2 class="mx-5">Solicitudes</h2>
        <span class="text-subtitle-2 text-center">{{
          position.name ? position.name : ''
        }}</span>
      </v-row>

      <!-- <v-row no-gutters justify="end" class="px-3">
        <v-btn
          small
          color="primary"
          dark
          class="mb-2"
          style="float: right"
          @click.prevent="handleCreate()"
          >Crear
        </v-btn>
      </v-row> -->

      <v-row class="mx-0">
        <label class="col-12 py-0">Filtrar por:</label>
        <v-col cols="12" lg="3" md="4" sm="6">
          <v-text-field
            dense
            solo
            hide-details="auto"
            label="Búsqueda"
            v-model="filters.search"
          />
        </v-col>
        <v-col cols="12" sm class="d-flex align-end justify-end">
          <v-btn
            small
            color="primary"
            max-width="35"
            min-width="auto"
            dark
            class="px-1 py-1"
            v-if="filterActivated"
            @click.prevent="
              filterActivated = false
              cleanFilters()
              getJobApplications()
            "
          >
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                max-width="35"
                min-width="auto"
                dark
                small
                class="px-1 py-1 ml-1"
                @click.prevent="
                  filterActivated = true
                  getJobApplications()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loadingJobApplications"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="extraButtoms"
        :showCreate="false"
        :showDelete="true"
        :showPagination="pages > 1 ? true : false"
        :showEdit="false"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
        @handleShowData="showJobApplication($event)"
      />
    </v-card>
    <modal name="jobApplicationModal" :height="'80%'" :width="'80%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <v-tabs v-model="model" centered slider-color="teal">
          <v-tab href="#1"> Solicitud </v-tab>
          <v-tab href="#2"> Entrevistas </v-tab></v-tabs
        >
        <keep-alive>
          <v-tabs-items v-model="model">
            <v-tab-item value="1">
              <h2 class="text-center ma-3">Detalle de la solicitud</h2>

              <span v-if="item.created_at" class="mx-3"
                >Fecha de la solicitud: {{ toLocaleDateString(createdAt, 'es-VE') }}.
                {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
              >
              <v-row class="mx-0 mt-4" v-if="item.id">
                <v-col cols="12" sm="6" lg="6" md="6">
                  <v-list
                    two-line
                    dense
                    max-width="350"
                    class="mx-auto"
                    elevation="2"
                    rounded
                  >
                    <v-list-item dense>
                      <v-img
                        class="mx-auto"
                        :src="item.uploads.find((file) => file.type === 'avatar').route"
                        dark
                        contain
                        width="100%"
                        max-width="250"
                      />
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-account </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          `${item.name} ${item.lastname}`
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Nombre del solicitante</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset />

                    <v-list-item dense>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-card-account-details </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          item.identification_num
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.identification_type
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="6" lg="6" md="6">
                  <v-list
                    two-line
                    dense
                    max-width="350"
                    class="mx-auto"
                    elevation="2"
                    rounded
                  >
                    <v-list-item dense v-if="item.local_phone">
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-phone </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          ><a
                            class="text-decoration-none"
                            :href="`tel:${item.local_phone}`"
                            >{{
                              item.local_phone !== '' ? item.local_phone : 'No definido'
                            }}</a
                          ></v-list-item-title
                        >
                        <v-list-item-subtitle>Teléfono Fijo</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-cellphone </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          ><a class="text-decoration-none" :href="`tel:${item.phone}`">{{
                            item.phone !== '' ? item.phone : 'No definido'
                          }}</a></v-list-item-title
                        >
                        <v-list-item-subtitle>Teléfono Personal</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset />

                    <v-list-item dense v-if="item.email">
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-email </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          ><a
                            class="text-decoration-none"
                            :href="`mailto:${item.email}`"
                            >{{ item.email }}</a
                          ></v-list-item-title
                        >
                        <v-list-item-subtitle>Correo electrónico</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset />

                    <v-list-item v-if="item.address">
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-map-marker </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{ item.address }}</v-list-item-title>
                        <v-list-item-subtitle>Zona de Residencia</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset />

                    <v-list-item dense>
                      <v-list-item-content>
                        <v-btn
                          block
                          color="primary"
                          :href="item.uploads.find((file) => file.type === 'cv').route"
                          download
                        >
                          Descargar CV
                        </v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col cols="12">
                  <v-simple-table class="mb-4">
                    <template v-slot:default>
                      <thead>
                        <tr class="">
                          <th class="text-left text-subtitle-1 font-weight-bold">
                            Requisitos para el cargo
                          </th>
                          <!-- <th class="text-left">Minimo Esperado</th> -->
                          <th class="text-left text-body-1 font-weight-bold">
                            Valor mínimo
                          </th>
                          <th class="text-left text-body-1 font-weight-bold">
                            Valor específico
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(positionSkill, i) in item.position_offer.position
                            .position_skills"
                          :key="i"
                        >
                          <td>{{ positionSkill.skill.name }}</td>
                          <!-- <td>{{ item.minimun }}</td> -->
                          <td>
                            <b>{{
                              handleSkillValue(
                                positionSkill.skill.field_type,
                                positionSkill
                              )
                            }}</b>
                          </td>
                          <td>
                            <v-text-field
                              style="max-width: 100px"
                              placeholder="Valor asignado"
                              v-if="positionSkill.skill.field_type !== 'boolean'"
                              hide-details="auto"
                              :value="
                                item.applicant_attrs.find(
                                  (item) => item.position_skill_id === positionSkill.id
                                ).value
                              "
                              readonly
                            />
                            <v-radio-group
                              style="max-width: 150px"
                              readonly
                              v-else
                              dense
                              hide-details
                              :value="
                                parseInt(
                                  item.applicant_attrs.find(
                                    (item) => item.position_skill_id === positionSkill.id
                                  ).value
                                )
                              "
                              :column="false"
                            >
                              <v-row align="center">
                                <v-radio label="Si" :value="1" class="mx-2" />
                                <v-radio label="No" :value="0" class="mx-2" />
                              </v-row>
                            </v-radio-group>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="2">
              <interviews
                :applicationId="item.id"
                :companyId="company.id" /></v-tab-item></v-tabs-items
        ></keep-alive>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <v-row no-gutters>
          <v-btn
            small
            color="success"
            class="mx-auto"
            @click="acceptApplication()"
            v-if="item.status === 0"
          >
            Admitir
          </v-btn>
          <v-btn
            small
            color="danger"
            class="mx-auto"
            @click="rejectApplication()"
            v-if="item.status === 0"
          >
            Rechazar
          </v-btn>
          <v-btn
            small
            color="primary"
            class="mx-auto"
            @click="$modal.hide('jobApplicationModal')"
          >
            Cerrar
          </v-btn>
        </v-row>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import Interviews from '@/views/HumanTalent/CRUDs/Interviews.vue'
  import { decryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    name: 'PositionApplications',
    components: {
      SimpleList,
      Interviews,
    },
    data() {
      return {
        model: 1,
        loadingJobApplications: false,
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        position: {},
        page: 1,
        pages: 1,
        activeInterview: false,
        items: [],
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        interviews: [],

        expantionControl: [],
        interview: {
          validation: false,
          user: null,
          type: null,
          observation: '',
          conclusion: '',
          url: '',
          details: '',
          date: null,
          time: null,
        },
        loadingText: 'Cargando registros',
        filterActivated: false,
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Nombre Completo', value: 'fullname' },
          { text: 'Género', value: 'genreText' },
          { text: 'Estatus', value: 'statusText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ],
        filters: {
          search: '',
        },
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        interviewOptions: [
          { id: 0, name: 'Online' },
          { id: 1, name: 'Presencial' },
        ],
        itemsPeriodicity: [
          { id: 0, name: 'Diario' },
          { id: 1, name: 'Semanal' },
          { id: 2, name: 'Quincenal' },
          { id: 3, name: 'Mensual' },
          { id: 4, name: 'Trimestral' },
          { id: 5, name: 'Semestral' },
          { id: 6, name: 'Anual' },
        ],

        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
        extraButtoms: [
          {
            text: 'Ver solicitud',
            icon: 'mdi-eye',
            event: { active: true, name: 'handleShowData' },
          },
        ],
        navigationElements: [
          {
            text: 'Talento Humano',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/AdminPanel',
            href: '/administrative/human-talent/admin-panel',
          },
          {
            text: 'Bolsa de empleo',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/JobBank',
            href: '/administrative/human-talent/manuals',
          },
          {
            text: 'Solicitudes',
            disabled: true,
          },
        ],
      }
    },
    created() {
      this.getPosition()
      this.getJobApplications()
    },
    computed: {
      ...mapGetters(['locale', 'userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      cleanFilters() {
        this.filters = {
          search: '',
        }
      },
      getPosition() {
        this.$axios
          .get('positions/' + this.$route.params.positionOfferId)
          .then((response) => {
            this.position = response.data
          })
      },
      async getJobApplications() {
        this.loadingJobApplications = true
        let queryParams = ''
        let orderString = ''
        let searchString = ''
        const withString =
          '&with[]=uploads&with[]=positionOffer.position.positionSkills.skill&with[]=applicantAttrs'
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
        }

        queryParams = searchString + withString

        this.$axios
          .get(
            'job-applications?position_offer_id=' +
              this.$route.params.positionOfferId +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.map((x) => {
              x.type === 0 ? (x.typeText = 'Online') : (x.typeText = 'Presencial')

              if (x.genre === 0) {
                x.genreText = 'Masculino'
              } else x.genre = 'Femenino'

              x.fullname = x.name + ' ' + x.lastname

              switch (x.status) {
                case 0:
                  x.statusText = 'Recibido'
                  break
                case 1:
                  x.statusText = 'Admitido'
                  break
                case 2:
                  x.statusText = 'Rechazado'
                  break
              }

              return x
            })
            this.loadingJobApplications = false
          })
      },
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getJobApplications()
      },
      async showJobApplication(item) {
        this.item = item
        this.createdAt = new Date(item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )
        this.$modal.show('jobApplicationModal')
      },
      acceptApplication() {
        const data = {
          status: 1,
        }
        this.$axios.patch('job-applications/' + this.item.id, data).then(() => {
          this.getJobApplications()
          this.$modal.hide('jobApplicationModal')
        })
      },
      rejectApplication() {
        const data = {
          status: 2,
        }
        this.$axios.patch('job-applications/' + this.item.id, data).then(() => {
          this.getJobApplications()
          this.$modal.hide('jobApplicationModal')
        })
      },

      isURL(str) {
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },
      handleSkillValue(type, skill) {
        switch (type) {
          case 'integer':
            return parseInt(skill.value)
          case 'number':
            return skill.value
          case 'boolean':
            return 'Requerido'

          default:
            return null
        }
      },

      async deleteItem(item) {
        this.$axios.delete('job-applications/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.loadingJobApplications = true
          this.getJobApplications()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getJobApplications()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
</style>
